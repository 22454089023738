import React, { useRef, useState, useEffect } from "react";
//import ScrollPetalGallerySelectorBar from "./ScrollPetalGallerySelectorBar";
import './styles/scrollPetalGallery.css';
import bloomBox from './images/boxes/bloomBox.jpg';
import budBox4 from './images/boxes/budBox4.jpg';
import GenericBoxes1 from './images/boxes/GenericBoxes1.jpg';
import GenericBoxes2 from './images/boxes/GenericBoxes2.jpg';
import poppyBox2 from './images/boxes/poppybox2.jpg';
import rainbowBox from './images/boxes/rainbowBox.jpg';
import stillbirthBox from './images/boxes/stillbirthBox2.jpg';

  const ScrollPetalGallery = () => {
    const parentDivRef = useRef(null);
    //const scrollPetalRef = useRef(null);
    //const [name, setName] = useState(""); // Assuming you still need state for name
    const [scrollActive, setScrollActive] = useState(false);
  
    const handleMouseEnter = () => setScrollActive(true);
    const handleMouseLeave = () => setScrollActive(false);
    const handleMouseMove = (event) => {
      console.log("OnMouseMove...")
      if (!scrollActive) {
        console.log("Scroll is not active.")
        return;
      }
      const galleryWidth = parentDivRef.current.offsetWidth;
      const scrollThresholdLeft = galleryWidth / 3;
      const scrollThresholdRight = 2 * galleryWidth / 3;
  
      if (event.clientX < scrollThresholdLeft) {
        // Scroll Left
        console.log("Scrolling left")
        parentDivRef.current.scrollLeft -= 10; // Adjust scroll speed as needed
      } else if (event.clientX > scrollThresholdRight) {
        // Scroll Right
        console.log("Scrolling right")
        parentDivRef.current.scrollLeft += 10;
      }
    };

  
    return (
      
        
            <div class="horizontal-scroll-wrapper">
                <div class="img-wrapper slower scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={bloomBox} alt="Box"></img>
                </a>
                </div>
                
                <div class="img-wrapper faster scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={budBox4} alt="Box"></img>
                </a>
                </div>

                <div class="img-wrapper slower vertical scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={GenericBoxes1} alt="Box"></img>
                </a>
                </div>

                <div class="img-wrapper slower slower-down scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={GenericBoxes2} alt="Box"></img>
                </a>
                </div>

                <div class="img-wrapper scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={poppyBox2} alt="Box"></img>
                </a>
                </div>

                <div class="img-wrapper slower scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={rainbowBox} alt="Box"></img>
                </a>
                </div>

                <div class="img-wrapper faster1 scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={stillbirthBox} alt="Box"></img>
                </a>
                </div>
                
                <div class="img-wrapper slower slower2 scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={bloomBox} alt="Box"></img>
                </a>
                </div>
                
                <div class="img-wrapper scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={GenericBoxes1} alt="Box"></img>
                </a>
                </div>
                
                <div class="img-wrapper slower scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={poppyBox2} alt="Box"></img>
                </a>
                </div>
                
                <div class="img-wrapper slower last scrollGalleryPhoto">
                <a href="poppycareco.com" target="_blank" rel="noopener"><img src={GenericBoxes2} alt="Box"></img>
                </a>
                
            </div>
            
            </div>
    
  );
};

export default ScrollPetalGallery;