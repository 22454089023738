import React from "react";

class Footer extends React.Component {

render(){
    return <footer style={{bottom: 0, width: '100%' }} class = "accent1">
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
      <div>
        Email: poppycareco@gmail.com
      </div>
      
      <div>
        Instagram: <a href="https://www.instagram.com/poppycareco/">@poppycareco</a>
      </div>
    </div>
  </footer>;
}


}

export default Footer;
/*
Place this between the email and insta divs to add a phone number back in
<div>
        Phone: 555-555-5555
      </div>
*/
//Footer style used to contain ", position: 'absolute'"