import React, { useState, useEffect } from "react";
import './styles/boxGallery.css';
//import { Gallery, Item } from "react-photoswipe-gallery";
//import '../../../node_modules/photoswipe/src/js/photoswipe.js';
//import '../../../node_modules/photoswipe/src/js/lightbox/lightbox.js';
import img1 from './images/poppycarecoLogo.JPG';
import img2 from './images/poppycarecoLogo.JPG';
import img3 from './images/poppycarecoLogo.JPG';
import img4 from './images/poppycarecoLogo.JPG';
import img5 from './images/poppycarecoLogo.JPG';
import img6 from './images/poppycarecoLogo.JPG';
import img7 from './images/poppycarecoLogo.JPG';
import img8 from './images/poppycarecoLogo.JPG';
import img9 from './images/poppycarecoLogo.JPG';

class BoxGallery extends React.Component{

    render(){
      return (
        <div class = 'box-gallery-container'>Box Gallery</div>
        /*
        <Gallery>
        <Item
          original="https://picsum.photos/1024/768?image=2"
          thumbnail="https://picsum.photos/200/300?image=2"
          width="1024"
          height="768"
        >
          {({ ref, open }) => (
            <img
              ref={ref}
              onClick={open}
              src="https://picsum.photos/200/300?image=2"
            />
          )}
        </Item>
      </Gallery>*/
      );
  }
}




/*
class BoxGallery extends React.Component {
  constructor() {
    super();
    this.state = {
      currentImageIndex: 0,
      images: [
        {
          src: img1,
          alt: "PoppyCareCoLogo",
        },
        {
          src: img2,
          alt: "PoppyCareCoLogo",
        },
        {
          src: img3,
          alt: "PoppyCareCoLogo",
        },
      ],
      autoRotate: true,
    };
  }

  componentDidMount() {
    if (this.state.autoRotate) {
      this.intervalId = setInterval(() => {
        this.nextImage();
      }, 7000);
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  nextImage() {
    this.state.autoRotate = false;
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex + 1) % prevState.images.length,
    }));
  }

  previousImage() {
    this.state.autoRotate = false;
    this.setState((prevState) => ({
      currentImageIndex: (prevState.currentImageIndex - 1 + prevState.images.length) % prevState.images.length,
    }));
  }

  render() {
    const { currentImageIndex, images, autoRotate } = this.state;
    return (
      <div>
        <img
          key={currentImageIndex}
          src={images[currentImageIndex].src}
          alt={images[currentImageIndex].alt}
        />
        <div>
          <button onClick={() => this.previousImage()}>Previous</button>
          <button onClick={() => this.nextImage()}>Next</button>
          <button onClick={() => this.setState((prevState) => ({ autoRotate: !prevState.autoRotate }))}>
            {autoRotate ? "Disable Auto Rotate" : "Enable Auto Rotate"}
          </button>
        </div>
      </div>
    );
  }
}
*/

export default BoxGallery;