import React, { useState, useEffect } from "react";
import OriginalBoxDisplay from "./OriginalBoxDisplay";
import BudBoxDisplay from "./BudBoxDisplay";
import BloomBoxDisplay from "./BloomBoxDisplay";
import RainbowBoxDisplay from "./RainbowBoxDisplay";
import StillbornBoxDisplay from "./StillbornBoxDisplay";

class ProductsList extends React.Component{

    render(){
      return (
        <div>
            <div class='sectionTitle'>
                <h1>Care packages:</h1>
                <h5>All boxes can be picked up for free, or shipped for an additional cost.</h5>
            </div>
            <div class='rowFlexContainerTop'>
                <div class='columnFlexContainer'>
                
                    <OriginalBoxDisplay />
                    <BudBoxDisplay />
                    <BloomBoxDisplay />
                    <div style={{height: '5vh'}}> </div>
                </div>
                <div class='columnFlexContainer'>
                    <RainbowBoxDisplay />
                    <StillbornBoxDisplay />
                </div>
                
            </div>
        </div>
      );
  }
}

export default ProductsList;