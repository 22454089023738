import React, {useEffect, useRef} from "react";
import './styles/mainBody.css';
import './styles/linkStyles.css';
//import BoxGallery from "./BoxGallery";
//import WhoWeAre from "./WhoWeAre";
//import ProductsList from "./ProductsList";

function MainBody({ videoUrl, paragraphText }) {
    const videoRef = useRef(null);
    const contentRef = useRef(null);
  
    useEffect(() => {
      // Play the video automatically when mounted
      videoRef.current.play();
  
      // Fade in the content div with a smooth transition
      contentRef.current.style.opacity = 0;
      const fadeIn = () => {
        contentRef.current.style.opacity = 1;
      };
      setTimeout(fadeIn, 1000); // Adjust delay as needed
    }, []);

    useEffect(() => {
      const welcomeTextDiv = document.querySelector('.welcome-text');
      const aboutUsTextDiv = document.querySelector('.about-us-text');
      const quickLinksDiv = document.querySelector('.quickLinks');
      setTimeout(() => {
        welcomeTextDiv.classList.add('welcome-text-to-top'); // Add the animation class
        console.log("APPLIED: welcome-text-to-top")
      }, 2900); // delay before welcome text moves up to align to header
      setTimeout(() => {
        welcomeTextDiv.classList.add('welcome-text-merge-header'); // Add the animation class
        quickLinksDiv.classList.add('quickLinksReshape');
        console.log("APPLIED: welcome-text-merge-header")
        aboutUsTextDiv.classList.add('opacity-1'); // Add the animation class
        console.log("APPLIED: opacity-1")
      }, 3700); // delay before welcome text becomes fixed and expands to mesh with header, while about us text fades in
    }, []);
  
    return (
      <div className="main-body">
        <video ref={videoRef} autoPlay muted playsInline className="main-body-video">
          <source src={videoUrl} type="video/mp4" />
          {/* Add fallback for other video formats if needed */}
        </video>
        <div ref={contentRef} className="about-us-content">
          <p class="welcome-text">{paragraphText}</p>
        </div>
        <div class='about-us-text'>
          <h1>‎ ‎ ‎ ‎ ‎ I'm glad you found us!</h1>
          <p style={{fontSize:'1.5vw', padding: '1%'}}>
          We offer care packages specifically designed for women walking through pregnancy loss and infertility.
            <br /><br />
            Did you know that 1 in 4 pregnancies ends in miscarriage? And that 1 in 8 women will struggle to conceive? When someone you love is walking this painful road, sometimes it feels impossible to know how to show your support. 
            <br /><br />
            Poppy Care Co. was birthed out of my passion for creating care packages. I’ve always loved putting them together - whether to toast someone’s new home, celebrate a birthday, or lift spirits during hard times. 
            <br /><br />
            I offer thoughtfully assembled gift boxes that you can purchase for pickup or shipping, to gift a friend or family member who needs some TLC. Send me a message and let’s get started!
            <br/> <br />
            My email is poppycareco@gmail.com, and my Instagram is <a href="https://www.instagram.com/poppycareco/">@poppycareco</a>
          </p>
        </div>
      </div>
    );
  }
  
  export default MainBody;