import React from "react";
import logo from './images/poppycarecoLogo.JPG'
import './styles/headerStyles.css';
import './styles/linkStyles.css';

class MainHeader extends React.Component {

render(){
    return <header  class = "accent1">
        <div class="headerContainer">
          <div class = 'logoFrame'>
          <img src={logo} alt="PoppyCareCo Logo" />
          </div>
          <div class="quickLinks">
            <nav>
              <ul style={{ display: 'flex', listStyle: 'inside'}}>
                <p></p><p></p><p></p>
                <li style={{ marginRight: '1vw' }}><a href="#">About Us</a></li>
                <li style={{ marginRight: '1vw' }}><a href="#">Products</a></li>
                <li style={{ marginRight: '1vw' }}><a href="#">Gallery</a></li>
              </ul>
            </nav>
          </div>
        </div>
        
      </header>;
}


}
/*

<div class="headerSpacerContainer">
          <div class = 'logoFrame'>
          <img src={logo} alt="PoppyCareCo Logo" />
          </div>
          <div style={{fontSize: '1.7vw', backgroundColor: 'white', marginRight:'.6%', padding: '.7%', borderTopLeftRadius:'20%', borderBottomLeftRadius:'20%'}}>
            <nav>
              <ul style={{ display: 'flex', listStyle: 'inside'}}>
                <li style={{ marginRight: '10px' }}><a href="#">About Us</a></li>
                <li style={{ marginRight: '10px' }}><a href="#">Products</a></li>
                <li style={{ marginRight: '0px' }}><a href="#">Gallery</a></li>
              </ul>
            </nav>
          </div>
        </div>

        */
export default MainHeader;