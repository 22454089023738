import React, { useState, useEffect } from "react";
import Socks from './images/pngs/Socks.png';
import Lotion from './images/pngs/CurelLotion.PNG';
import Tea from './images/pngs/TeaBags.PNG';
import Journal from './images/pngs/Notebook.PNG';
import EyePack from './images/pngs/EyeIceMask.PNG';
import AngelNecklace from './images/pngs/AngelNecklace.PNG';
import candle from './images/pngs/Candle.png';
import Chapstick from './images/pngs/Chapstick.PNG';
import CourageousBook from './images/pngs/CourageouslyExpectingBook.PNG';
import FaceWipes from './images/pngs/FaceWipes.PNG';
import FridaPeri from './images/pngs/FridaPeri.PNG';
import HeldDevotional from './images/pngs/HeldDevotional.PNG';
import HydratingMask from './images/pngs/HydratingFaceMask.PNG';
import MamaNecklace from './images/pngs/MamaNecklace.PNG';
import MaxiPads from './images/pngs/MaxiPads.PNG';
import MuslinSwaddle from './images/pngs/MuslinSwaddleBlanket.PNG';
import Poppy from './images/pngs/poppycarecoCropped.png';
import RainbowNecklace from './images/pngs/RainbowNecklace.PNG';
import Scrunchie from './images/pngs/Scrunchie.PNG';
import Tucks from './images/pngs/Tucks.PNG';
import BloomBox from './images/boxes/bloomBox.jpg';
import './styles/productStyles.css';

class BloomBoxDisplay extends React.Component{

    render(){
      return (
        <div class='rowFlexContainer' style={{
            height: '70vh'
          }}>
            <div class='boxDisplayFrame' style={{height: '70vh'}}>

            <img src={BloomBox} alt="Bloom Box" />

            </div>
            <div class='boxDetails' style={{height: '70vh'}}>
            <h1 class='productTitle'>Bloom Postpartum Box</h1>
            <h5 class='productPrice'>$70 for the basic, $115 for the deluxe</h5>
            <p class='productDescription'>Contains a "mama" necklace, a journal with pens, a hand-poured soy candle, tea bags, fuzzy socks, a scrunchie, face wipes, hand lotion, chapstick, and a hydrating face mask.<br /> <br />If you decide to get the deluxe version, it also includes a frida peri bottle, cocoa tummy butter, and tuck's lidocaine cream + witch hazel wipes.</p>
            </div>
        </div>
      );
  }
}

export default BloomBoxDisplay;