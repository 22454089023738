import MainHeader from './MainHeader';
import MainBody from './MainBody';
import Footer from './Footer';
import BoxGallery from './BoxGallery';
import ProductsList from './ProductsList';
import ScrollPetalGallery from './ScrollPetalGallery';
//import PhotoSwipeLightbox from 'photoswipe/lightbox';
//import PhotoSwipe from 'photoswipe';
//import '../../../node_modules/photoswipe/src/js/photoswipe.js';
//import '../../../node_modules/photoswipe/src/js/lightbox/lightbox.js';
//import "photoswipe/dist/photoswipe.css";
//import "photoswipe/dist/default-skin/default-skin.css";
import './styles/mainStyles.css';
import videoUrl from './videos/MutedBloomFinal.mp4'
//import WhoWeAre from './WhoWeAre';
import HoverProductGallery from './HoverProductGallery';
//import img1 from './images/poppycarecoLogo.JPG';
//import img2 from './images/poppycarecoLogo.JPG';


/**
const App = () => (
  <div>
    <ScrollPetalGallery />
    <Footer />
  </div>
);
*/
const App = () => (
  <div class="app-container-div">
    <MainHeader />
    <MainBody videoUrl={videoUrl} paragraphText="Welcome to PoppyCareCo."/>
    <ScrollPetalGallery />
    <ProductsList />
    <Footer />
  </div>
);
/*
const App = () => (
  <div id="app-container-div">
  <MainHeader />
    <MainBody videoUrl={videoUrl} paragraphText="Welcome to PoppyCareCo."/>
    
    <BoxGallery />
    <ScrollPetalGallery />
    <ProductsList />
    <Footer />
    <HoverProductGallery />
    
  </div>
);
*/

export default App;