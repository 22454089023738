import React, { useState, useEffect } from "react";
import Socks from './images/pngs/Socks.png';
import Lotion from './images/pngs/CurelLotion.PNG';
import Tea from './images/pngs/TeaBags.PNG';
import Journal from './images/pngs/Notebook.PNG';
import EyePack from './images/pngs/EyeIceMask.PNG';
import AngelNecklace from './images/pngs/AngelNecklace.PNG';
import candle from './images/pngs/Candle.png';
import Chapstick from './images/pngs/Chapstick.PNG';
import CourageousBook from './images/pngs/CourageouslyExpectingBook.PNG';
import FaceWipes from './images/pngs/FaceWipes.PNG';
import FridaPeri from './images/pngs/FridaPeri.PNG';
import HeldDevotional from './images/pngs/HeldDevotional.PNG';
import HydratingMask from './images/pngs/HydratingFaceMask.PNG';
import MamaNecklace from './images/pngs/MamaNecklace.PNG';
import MaxiPads from './images/pngs/MaxiPads.PNG';
import MuslinSwaddle from './images/pngs/MuslinSwaddleBlanket.PNG';
import Poppy from './images/pngs/poppycarecoCropped.png';
import RainbowNecklace from './images/pngs/RainbowNecklace.PNG';
import Scrunchie from './images/pngs/Scrunchie.PNG';
import Tucks from './images/pngs/Tucks.PNG';
import originalBox from './images/boxes/poppybox2.jpg';

class OriginalBoxDisplay extends React.Component{

    render(){
      return (
        <div class='rowFlexContainer'>
            <div class='boxDisplayFrame' >

            <img src={originalBox} alt="Original Box" />

            </div>
            <div class='boxDetails'>
                <h1 class='productTitle'>Original Box</h1>
                <h5 class='productPrice'>$60 without "Held" devotional, $85 with</h5>
                <p class='productDescription'>
                    Contains fuzzy socks, hand lotion, tea bags, a journal with pens, an eye ice pack, an angel wing necklace, a hydrating face mask, a hand-poured soy candle, and the devotional book "Held" by Abbey Wedgeworth.
                </p>
            </div>
        </div>
      );
  }
}

export default OriginalBoxDisplay;